import React from 'react';
import classNames from 'classnames/bind';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './Error.module.scss';

export default function InvalidLinkScreen() {
  return (
    <Layout>
      <Title label={_('Der Link ist nicht mehr gültig')} />
      <p className={text_styles.body1_bright_centered}>
        {_(
          'Der Link wurde deaktiviert. Kontaktieren Sie bitte unseren Support, falls Sie eine neue Einladung benötigen.',
        )}
      </p>
      <p className={styles.email_link}>
        <a
          href={`mailto:${_('kundenservice@getmomo.de')}`}
          rel="noreferrer"
          target="_blank"
          className={classNames(
            text_styles.body1_bright_bold_centered,
            styles.no_underline,
          )}>
          {_('kundenservice@getmomo.de')}
        </a>
      </p>
    </Layout>
  );
}
