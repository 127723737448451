import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import LanguageSelector from './language_selector/LanguageSelector.js';
import styles from './FormWrapper.module.scss';

export default function FormWrapper({children, tenant_display_name}) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className={classNames(
            tenant_display_name ? styles.title_bar : styles.language_selector,
          )}>
          {tenant_display_name && (
            <p
              className={classNames(
                text_styles.body1_bold_left,
                styles.display_name,
              )}>
              {tenant_display_name.length > 30 ? (
                <div className={styles.truncation_wrapper}>
                  <p className={styles.truncated_text}>{tenant_display_name}</p>
                  <p>
                    {tenant_display_name.slice(-15, tenant_display_name.length)}
                  </p>
                </div>
              ) : (
                <p>{tenant_display_name}</p>
              )}
            </p>
          )}
          <div>
            <LanguageSelector />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
