import React, {useEffect, useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Button from 'shared-web/components/Button.js';
import HintModal from 'shared-web/components/modal/HintModal.js';
import {_} from 'shared/l10n.js';
import {TENANT_PATHS} from 'shared/constants.js';

import styles from './IncomeProofMissingModal.module.scss';

const COPIED_TO_CLIPBOARD_TIMEOUT = 5000;

export default function IncomeProofMissingModal({show, onClose}) {
  const history = useHistory();
  const [copied_to_clipboard, setCopiedToClipboard] = useState(false);
  const link = generateOnboardingEntryLink(history);

  const handleShare = useCallback(() => {
    if (navigator.share) {
      navigator
        .share({
          title: _('Momo Mietkaution Unterlagen ausfüllen'),
          url: link,
        })
        .finally(onClose);
    } else {
      navigator.clipboard.writeText(link);
      setCopiedToClipboard(true);
    }
  }, [onClose, link]);

  useEffect(() => {
    if (!copied_to_clipboard) return;
    const timeout_handle = setTimeout(() => {
      setCopiedToClipboard(false);
    }, COPIED_TO_CLIPBOARD_TIMEOUT);
    return () => {
      clearTimeout(timeout_handle);
    };
  }, [copied_to_clipboard]);

  return (
    <HintModal
      onHide={onClose}
      show={show}
      title={_('Ein Foto Ihres letzten Einkommensnachweises wird benötigt')}
      text={_(
        'Sollten Sie Probleme beim Hochladen von Fotos haben, ist der Dateizugriff in diesem Browser möglicherweise blockiert. Sie können den angezeigten Link kopieren, um den Prozess in einem anderen Browser auf Ihrem Smartphone oder PC abzuschließen.',
      )}>
      <div className={styles.wrapper}>
        <Button onClick={onClose} title={_('OK')} />
        <Button
          onClick={handleShare}
          title={copied_to_clipboard ? _('Link kopiert') : link}
          className={styles.link_button}
        />
      </div>
    </HintModal>
  );
}

function generateOnboardingEntryLink(history) {
  const url = new URL(window.location);
  url.pathname = TENANT_PATHS.WelcomeScreen;
  url.search = history.location.state.token;
  return url.toString();
}
