import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {RPC, NOT_FOUND, CONFLICT} from 'shared/api.js';
import Button from 'shared-web/components/Button.js';
import classNames from 'classnames/bind';
import {_} from 'shared/l10n.js';
import {redirectExternal} from 'shared/utils.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {TENANT_PATHS} from 'shared/constants.js';
import terms_and_conditions_pdf from 'shared/assets/termsheets/2024-08.Nutzungsbedingungen.pdf';

import {confirm} from '../../effects.js';
import {isDevFormEnabled} from '../../lib/utils.js';
import LanguageSelector from '../../components/language_selector/LanguageSelector.js';

import image from './contract-example.png';
import styles from './WelcomeScreen.module.scss';

export default function WelcomeScreen() {
  const history = useHistory();
  const {search, state} = useLocation();
  const token = search.replace('?', '');
  const modal_confirm_visible = !!state?.optout;

  const [contract_details, setContractDetails] = useState();
  const [has_application_draft, setHasApplicationDraft] = useState(false);

  useEffect(() => {
    fetchContractDetails({
      token,
      history,
      setContractDetails,
      setHasApplicationDraft,
    });
  }, [token, history]);

  useEffect(() => {
    if (!modal_confirm_visible && has_application_draft && contract_details) {
      history.push(TENANT_PATHS.BasicInformationScreen, {
        contract_details,
        token,
      });
    }
  }, [
    contract_details,
    history,
    has_application_draft,
    token,
    modal_confirm_visible,
  ]);

  const confirmRejectInvitation = useCallback(async () => {
    if (
      !(await confirm({
        title: _('Möchten Sie das Angebot wirklich ablehnen?'),
        text: _(
          'Falls ja, können Sie die Wohnung nicht mehr mit dem Momo-Mietkautionsschutz beziehen und erhalten keine SMS-Erinnerung mehr.',
        ),
        proceed_label: _('Ja, jetzt löschen'),
      }))
    ) {
      return false;
    }

    rejectInvitation({token, history});
  }, [history, token]);

  useEffect(() => {
    if (modal_confirm_visible) confirmRejectInvitation();
  }, [confirmRejectInvitation, modal_confirm_visible]);

  if (!contract_details) return null;

  const {
    street_name,
    street_number,
    postal_code,
    region,
    tenant_provides_additional_data,
  } = contract_details;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LanguageSelector />
        <p className={classNames(text_styles.body2_left, styles.welcome)}>
          {_('Herzlich Willkommen')}
        </p>
        <p className={text_styles.huge_headline_left}>
          {_(
            'Für Ihre Kautionsbürgschaft benötigen wir ein paar Angaben von Ihnen',
          )}
        </p>

        {!tenant_provides_additional_data && (
          <>
            <p className={classNames(text_styles.caption_left, styles.address)}>
              {_('Adresse der neuen Mietwohnung')}
            </p>

            <p className={text_styles.body2}>
              {_('%1 %2', street_name, street_number)}
            </p>
            <p className={text_styles.body2}>
              {_('%1 %2', postal_code, region)}
            </p>
          </>
        )}

        <div className={styles.image}>
          <img
            src={image}
            alt="Mietkautionsbürgschaft"
            height="290px"
            width="328px"
          />
        </div>

        <p className={text_styles.body1_left}>
          {_(
            'Nach der erfolgreichen Überprüfung Ihrer Angaben, erhalten Sie ein unverbindliches Angebot.',
          )}
        </p>

        <div
          className={
            tenant_provides_additional_data
              ? styles.button_wrapper
              : styles.button_wrapper_address
          }>
          <Button
            title={_('Los geht’s →')}
            type="submit"
            className={styles.button}
            onClick={() => proceed({token, contract_details, history})}
          />
        </div>

        {!tenant_provides_additional_data && (
          <p className={text_styles.caption_left}>
            {_(
              'Per Klick auf „Los geht’s“ bestätigen Sie die Geltung der hier abrufbaren ',
            )}
            <a href={terms_and_conditions_pdf} target="_blank" rel="noreferrer">
              <span
                className={classNames(text_styles.caption_left, styles.link)}>
                {_('Nutzungsbedingungen')}
              </span>
            </a>
            {'.'}
          </p>
        )}

        <p
          className={classNames(
            text_styles.caption_left,
            styles.reject_invitation_text,
          )}>
          {_(
            'Falls Sie keine Einladungen und Erinnerungen mehr per SMS bzw. E-Mail erhalten möchten, klicken Sie bitte ',
          )}
          <span className={styles.link} onClick={confirmRejectInvitation}>
            {_('hier')}
          </span>
          {'.'}
        </p>

        <LegalLinks />
      </div>
    </div>
  );
}

async function rejectInvitation({token, history}) {
  let reject_feedback_token;

  try {
    ({reject_feedback_token} = await RPC('rejectInvitation', {token}));
  } catch (err) {
    const location =
      err.code === NOT_FOUND
        ? _('https://www.getmomo.de/der-link-ist-nicht-mehr-gultig/')
        : _('https://www.getmomo.de/fehler/');
    redirectExternal(location);
    return;
  }

  history.push(`${TENANT_PATHS.RejectFeedbackScreen}?${reject_feedback_token}`);
}

async function proceed({token, contract_details, history}) {
  try {
    await RPC('agreeToTerms', {token});
  } catch {
    history.push(TENANT_PATHS.GenericErrorScreen);
    return;
  }

  history.push(TENANT_PATHS.BasicInformationScreen, {
    contract_details,
    token,
  });
}

async function fetchContractDetails({
  token,
  history,
  setContractDetails,
  setHasApplicationDraft,
}) {
  let details;

  try {
    details = await RPC('getTenantApplicationState', {
      token,
      enable_dev_form_state: isDevFormEnabled(),
    });
  } catch (err) {
    if (err.code === NOT_FOUND) {
      history.push(TENANT_PATHS.InvalidLinkScreen);
    } else if (err.code === CONFLICT) {
      history.push(TENANT_PATHS.DataTransmittedScreen);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  if (details?.tenant_application_started_at) {
    setHasApplicationDraft(true);
  }
  setContractDetails(details);
}

function LegalLinks() {
  return (
    <div
      className={classNames(text_styles.caption_left, styles.legal_container)}>
      <a
        className={styles.legal_link}
        href={_('https://www.getmomo.de/impressum')}
        rel="noreferrer"
        target="_blank">
        <p className={text_styles.caption_left}>{_('Impressum')}</p>
      </a>
      <p className={styles.legal_separator}>{'|'}</p>
      <a
        className={styles.legal_link}
        href={_('https://www.getmomo.de/datenschutz')}
        rel="noreferrer"
        target="_blank">
        <p className={text_styles.caption_left}>{_('Datenschutz')}</p>
      </a>
      <p className={styles.legal_separator}>{'|'}</p>
      <a
        className={styles.legal_link}
        href={_('https://www.getmomo.de/agb')}
        rel="noreferrer"
        target="_blank">
        <p className={text_styles.caption_left}>{_('AGB')}</p>
      </a>
    </div>
  );
}
