import React from 'react';
import {_} from 'shared/l10n.js';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import Icon from 'shared-web/components/Icon.js';

import {ReactComponent as ArrowLeftSvg} from '../assets/icons-arrow-left.svg';

import styles from './BackButton.module.scss';

export default function BackButton({onReturn}) {
  return (
    <button className={styles.button} onClick={onReturn}>
      <Icon>
        <ArrowLeftSvg />
      </Icon>
      <p className={classNames(text_styles.body1_centered, styles.text)}>
        {_('Zurück')}
      </p>
    </button>
  );
}
