import text_styles from 'shared-web/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';

import {ReactComponent as LockSvg} from './icon_lock.svg';
import styles from './SecurityBanner.module.scss';

export default function SecurityBanner() {
  return (
    <div className={styles.infomation_container}>
      <div className={styles.content}>
        <p className={text_styles.caption_bright_centered}>
          {
            // TRANSLATORS: There is an invisible character in the german source string; it's a soft hyphen between "Verschlüsselungs" and "­technologie"
            _(
              'Mit unserer Verschlüsselungs­technologie sind Ihre Daten bei uns gesichert.',
            )
          }
        </p>
        <LockSvg className={styles.icon} />
      </div>
    </div>
  );
}
