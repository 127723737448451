import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {
  PROVIDERS,
  STEP_NAME,
  TENANT_PATHS,
  VALIDATION_ERROR,
} from 'shared/constants.js';
import {_} from 'shared/l10n.js';
import {formatPrice} from 'shared/utils.js';

import CurrentIncomeStep from '../../../components/steps/CurrentIncomeStep/CurrentIncomeStep.js';
import {isDevFormEnabled} from '../../../lib/utils.js';
import {alertOfIncomeTooOld} from '../../../components/steps/CurrentIncomeStep/Incomes.js';
import {alert} from '../../../effects.js';

export default function CurrentIncomeScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {
    provider,
    has_second_tenant,
    tenant_display_name,
    tenants,
    cold_rent_cents,
  } = contract_details;

  const goToNextStep = useCallback(
    ({fields, setError, setIsLoading}) => {
      proceed({
        token,
        fields,
        history,
        setError,
        setIsLoading,
        cold_rent_cents,
      });
    },
    [history, token, cold_rent_cents],
  );

  const saveAndGoBack = useCallback(
    async ({fields}) => {
      tenants[0] = {...tenants[0], ...fields};
      const {previous_step} = await RPC('getNavigationState', {
        path: TENANT_PATHS.CurrentIncomeScreen,
        contract_details,
      });
      history.push(previous_step, {contract_details, token});
    },
    [contract_details, history, token, tenants],
  );

  const bottom_navigation_label =
    provider === PROVIDERS.vvrb ? _('Noch 1 Schritt') : _('Noch 2 Schritte');

  return (
    <CurrentIncomeStep
      contract_details={{
        provider,
        ...tenants[0],
      }}
      goToNextStep={goToNextStep}
      saveAndGoBack={saveAndGoBack}
      bottom_navigation_label={bottom_navigation_label}
      tenant_display_name={has_second_tenant && tenant_display_name}
      tenant_index={has_second_tenant ? 0 : undefined}
    />
  );
}

async function proceed({
  token,
  fields,
  history,
  setError,
  setIsLoading,
  cold_rent_cents,
}) {
  setIsLoading(true);
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.current_income,
      ...fields,
      token,
      tenant_index: 0,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (
      err.code === BAD_REQUEST &&
      Array.isArray(err.data) &&
      err.data.length > 0
    ) {
      const {message: incomes_message} =
        err.data?.find?.(({path}) => path === '/incomes') || {};
      if (incomes_message === VALIDATION_ERROR.income_too_low_vvrb) {
        alertLowIncome();
        return;
      }
      if (
        incomes_message === VALIDATION_ERROR.income_less_than_2x_cold_rent_vvrb
      ) {
        alertLowIncomeRentRatio(cold_rent_cents);
        return;
      }

      if (anyIncomeTooOld(err)) {
        // Don't return so we set form errors
        alertOfIncomeTooOld();
      }

      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
      return;
    }
    history.push(TENANT_PATHS.GenericErrorScreen);
    return;
  } finally {
    setIsLoading(false);
  }

  history.push(next_step, {contract_details, token});
}

// https://github.com/get-momo/issues/issues/3155
function anyIncomeTooOld(err) {
  return err.data?.some((err) => {
    return (
      err.path.match(/^\/incomes\/\d+\/date$/) &&
      err.message === VALIDATION_ERROR.invalid
    );
  });
}

export function alertLowIncome() {
  alert({
    title: _('Leider liegt Ihr Einkommen unterhalb unserer Mindestgrenze'),
    text: _(
      'Wir können Ihnen unseren Service erst ab einem monatlichen Haushaltseinkommen von €1.500 netto anbieten. Sollte es Ihnen möglich sein, können Sie oder ein eventueller Mitbewohner einen weiteren Einkommensnachweis hinzufügen. Bei Fragen wenden Sie sich bitte an:',
    ),
    email: _('kundenservice@getmomo.de'),
  });
}

export function alertLowIncomeRentRatio(cold_rent_cents) {
  alert({
    title: _('Ihr Einkommen ist leider nicht ausreichend für unser Angebot'),
    text: _(
      'Wir können Ihnen unseren Service ab einem monatlichen Einkommen von 2× Ihrer Kaltmiete (%1) anbieten. Wenn es für Sie möglich ist, können Sie weitere Einkommensquellen ergänzen.',
      formatPrice(cold_rent_cents),
    ),
  });
}
