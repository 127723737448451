import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {_} from 'shared/l10n.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';

import FormWrapper from '../../../components/FormWrapper.js';
import BottomNavigation from '../../../components/BottomNavigation.js';
import RadioGroup from '../../../components/RadioGroup.js';
import FormHeader from '../../../components/FormHeader.js';
import {isDevFormEnabled} from '../../../lib/utils.js';

export default function SecondTenantScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({token, fields, history, setError}),
  );

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.SecondTenantScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  return (
    <FormWrapper>
      <FormHeader
        title={_(
          'Gibt es eine zweite Person im Mietvertrag, die Sie hinzufügen möchten?',
        )}
        subtitle={_(
          'Mit einem zweiten Einkommen erhöhen Sie die Chance akzeptiert zu werden.',
        )}
      />

      <Controller
        control={control}
        name="has_second_tenant"
        render={({field: {onChange, value, name}}) => (
          <RadioGroup
            name={name}
            onChange={onChange}
            value={value}
            error={errors[name]?.message}
          />
        )}
      />
      <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />
    </FormWrapper>
  );
}

async function proceed({token, fields, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.second_tenant,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
