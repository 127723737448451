import React, {forwardRef} from 'react';
import Select from 'shared-web/components/Select';
import {_} from 'shared/l10n.js';

function SelectGender({label = _('Geschlecht'), ...props}, forwardedRef) {
  return (
    <Select
      ref={forwardedRef}
      label={label}
      options={[
        {id: 'female', name: _('weiblich')},
        {id: 'male', name: _('männlich')},
      ]}
      {...props}
    />
  );
}

export default forwardRef(SelectGender);
