import React from 'react';
import classNames from 'classnames/bind';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './Title.module.scss';

export default function Title({label, className}) {
  return (
    <p
      data-testid="title"
      className={classNames(
        styles.title,
        text_styles.body1_bright_bold_centered,
        className,
      )}>
      {label}
    </p>
  );
}
