import React from 'react';
import classNames from 'classnames/bind';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './FormHeader.module.scss';

export default function FormHeader({title, subtitle}) {
  return (
    <div className={styles.wrapper}>
      <p className={text_styles.huge_headline_left}>{title}</p>
      <p className={classNames(text_styles.body2_left, styles.subtitle)}>
        {subtitle}
      </p>
    </div>
  );
}
