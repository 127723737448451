import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {_} from 'shared/l10n.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import Icon from 'shared-web/components/Icon.js';

import {useMultiFileUpload} from '../lib/useFileUpload.js';
import {ReactComponent as CameraSvg} from '../assets/camera_icon.svg';

import Thumbnail from './Thumbnail.js';
import styles from './UploadDocument.module.scss';

export default function UploadDocument({
  label,
  name,
  error = '',
  getValues,
  setValue,
  'data-testid': dataTestId,
  ExplanationModal,
  action,
}) {
  const {documents, remove, uploadFiles} = useMultiFileUpload(getValues(name));
  const [explanation_modal_visible, setExplanationModalVisible] =
    useState(false);

  useEffect(() => {
    setValue(name, documents);
  }, [documents, setValue, name]);

  return (
    <div className={styles.container}>
      <div className={classNames(text_styles.caption_left, styles.label)}>
        {label}
      </div>

      {!ExplanationModal || documents.length > 0 ? (
        <>
          <label htmlFor={name} data-testid={dataTestId}>
            <PhotoButton action={action} />
          </label>
          <input
            id={name}
            name={name}
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            hidden
            multiple
            onClick={(e) => (e.target.value = null)}
            onChange={(e) => uploadFiles([...e.target.files])}
          />
          <div className={styles.thumbnails_container}>
            {documents.map((document, index) => {
              return (
                <Thumbnail
                  document={document}
                  key={index}
                  onClick={() => remove(document)}
                />
              );
            })}
          </div>
        </>
      ) : (
        <PhotoButton
          action={action}
          onClick={() => setExplanationModalVisible(true)}
        />
      )}

      {ExplanationModal && (
        <ExplanationModal
          name={name}
          show={explanation_modal_visible}
          documents={documents}
          uploadFiles={uploadFiles}
          onClose={() => setExplanationModalVisible(false)}
          dataTestId={dataTestId}
          setValue={setValue}
        />
      )}

      <p className={classNames(text_styles.caption_left, styles.error)}>
        {error}
      </p>
    </div>
  );
}

function PhotoButton({action = _('Foto hinzufügen'), onClick}) {
  return (
    <div className={styles.upload_button} onClick={onClick}>
      <Icon>
        <CameraSvg />
      </Icon>
      <p
        className={classNames(
          styles.text_wrapper,
          text_styles.button_bold_bright,
        )}>
        {action}
      </p>
    </div>
  );
}
