import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import DateInput from 'shared-web/components/DateInput.js';
import Select from 'shared-web/components/Select.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {isEuMember} from 'shared/utils.js';
import {_} from 'shared/l10n.js';
import {INVITATION_METHOD, RENTAL_CONTRACT_ORIGIN} from 'shared/constants.js';
import PhoneNumberInput from 'shared-web/components/phoneNumberInput/PhoneNumberInput.js';

import FormWrapper from '../../FormWrapper.js';
import BottomNavigation from '../../BottomNavigation.js';
import {getNationalityOptions} from '../../../lib/utils.js';
import {isUploading} from '../../../lib/useFileUpload.js';
import Input from '../../Input.js';
import UploadDocument from '../../UploadDocument.js';
import InvitationStepper from '../../InvitationStepper.js';
import FormHeader from '../../FormHeader.js';
import SelectGender from '../../SelectGender.js';

import styles from './PersonalDetailsStep.module.scss';

export default function PersonalDetailsStep({
  contract_details,
  goToNextStep,
  saveAndGoBack,
  bottom_navigation_label,
  tenant_display_name,
  tenant_index = null,
}) {
  const [is_submiting, setIsSubmitting] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) =>
    goToNextStep({fields, setError, setIsSubmitting}),
  );
  const onBack = useCallback(
    () => saveAndGoBack({fields: getValues()}),
    [saveAndGoBack, getValues],
  );

  const city_registrations = watch('city_registrations');
  const residence_permits = watch('residence_permits');
  const is_uploading = isUploading(city_registrations, residence_permits);

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  const citizenship = watch('citizenship');

  const {invitation_method, origin} = contract_details;
  const is_second_tenant = tenant_index === 1;
  const invited_via_sms = invitation_method === INVITATION_METHOD.sms;
  const invited_via_email = invitation_method === INVITATION_METHOD.email;
  const tenant_led_onboarding = origin === RENTAL_CONTRACT_ORIGIN.tenant;

  return (
    <FormWrapper tenant_display_name={tenant_display_name}>
      <FormHeader title={_('Persönliche Angaben')} />

      <Controller
        name="birth_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            name={name}
            label={_('Geburtsdatum')}
            error={errors[name]?.message}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
          />
        )}
      />

      <p className={classNames(text_styles.caption_left, styles.caption)}>
        {_('Familienstand')}
      </p>
      <Select
        options={getFamilyOptions()}
        error={errors.marital_status?.message}
        {...register('marital_status')}
      />

      <SelectGender
        className={styles.margin_bottom}
        error={errors.gender?.message}
        {...register('gender')}
      />

      <p className={classNames(text_styles.caption_left, styles.caption)}>
        {_('Geburtsland')}
      </p>
      <Select
        className={styles.margin_bottom}
        options={getNationalityOptions()}
        error={errors.birth_country?.message}
        {...register('birth_country')}
      />

      <Input
        type="text"
        label={_('Geburtsort')}
        error={errors.birth_place?.message}
        {...register('birth_place')}
      />

      <p className={classNames(text_styles.caption_left, styles.caption)}>
        {_('Staatsangehörigkeit')}
      </p>
      <Select
        className={styles.margin_bottom}
        options={getNationalityOptions()}
        error={errors.citizenship?.message}
        {...register('citizenship')}
      />

      {citizenship && !isEuMember(citizenship) && (
        <>
          <Controller
            control={control}
            name="city_registrations"
            render={({field: {onChange, name}}) => (
              <UploadDocument
                label={_('Foto Ihrer Meldebescheinigung')}
                onChange={onChange}
                error={errors[name]?.message}
                name={name}
                getValues={getValues}
                setValue={setValue}
              />
            )}
          />

          <Controller
            control={control}
            name="residence_permits"
            render={({field: {onChange, name}}) => (
              <UploadDocument
                label={_('Foto Ihres Aufenthaltstitels')}
                onChange={onChange}
                error={errors[name]?.message}
                name={name}
                getValues={getValues}
                setValue={setValue}
              />
            )}
          />
        </>
      )}
      {(is_second_tenant || invited_via_sms || tenant_led_onboarding) && (
        <Input
          label={_('E-Mail')}
          error={errors.email_address?.message}
          {...register('email_address')}
        />
      )}
      {(is_second_tenant || invited_via_email) && (
        <Controller
          control={control}
          name="phone_number"
          defaultValue={''}
          render={({field: {value, onChange, name}}) => (
            <PhoneNumberInput
              value={value}
              onChange={onChange}
              label={_('Telefonnummer')}
              error={errors[name]?.message}
            />
          )}
        />
      )}

      <BottomNavigation
        is_loading={is_submiting || is_uploading}
        onSubmit={onSubmit}
        onBack={onBack}
        label={bottom_navigation_label}
      />

      {tenant_index !== null && <InvitationStepper step={tenant_index + 1} />}
    </FormWrapper>
  );
}

function getFamilyOptions() {
  return [
    {id: 'SINGLE', name: _('ledig')},
    {id: 'MARRIED', name: _('verheiratet')},
    {id: 'WIDOWED', name: _('verwitwet')},
    {id: 'DIVORCED', name: _('geschieden')},
    {id: 'SEPARATED', name: _('getrennt lebend')},
  ];
}
