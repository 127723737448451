import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames/bind';
import Button from 'shared-web/components/Button.js';
import {_} from 'shared/l10n.js';
import {TENANT_PATHS} from 'shared/constants.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './ResendLinkScreen.module.scss';

export default function ResendLinkScreen() {
  const history = useHistory();
  const {tenant_phone_number} = history.location.state;

  return (
    <Layout display_customer_service_email={true}>
      <div className={styles.container}>
        <Title
          label={_(
            'Wir haben einen neuen Link an diese Telefonnummer gesendet, falls die Telefonnummer bei uns registriert war.',
          )}
        />
        <p className={text_styles.body1_bright_centered}>
          {tenant_phone_number}
        </p>
        <p
          className={classNames(
            text_styles.body1_bright_centered,
            styles.text,
          )}>
          {_(
            'Wenn Sie in ca. 1 Minute keine SMS bekommen haben, überprüfen Sie bitte Ihre Eingabe oder wenden Sie sich an unseren Support.',
          )}
        </p>
        <Button
          title={_('Nummer korrigieren')}
          secondary={true}
          onClick={() => history.push(TENANT_PATHS.HomeScreen)}
        />
      </div>
    </Layout>
  );
}
