import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';

import CurrentAddressStep from '../../../components/steps/CurrentAddressStep/CurrentAddressStep.js';
import {isDevFormEnabled} from '../../../lib/utils.js';

export default function CurrentAddressScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {has_second_tenant, tenant_display_name, tenants} = contract_details;

  const goToNextStep = useCallback(
    ({fields, setError}) => {
      proceed({token, fields, history, setError});
    },
    [history, token],
  );

  const saveAndGoBack = useCallback(
    async ({fields}) => {
      tenants[0] = {...tenants[0], ...fields};
      const {previous_step} = await RPC('getNavigationState', {
        path: TENANT_PATHS.CurrentAddressScreen,
        contract_details,
      });
      history.push(previous_step, {contract_details, token});
    },
    [contract_details, history, token, tenants],
  );

  return (
    <CurrentAddressStep
      contract_details={{...contract_details, ...tenants[0]}}
      goToNextStep={goToNextStep}
      saveAndGoBack={saveAndGoBack}
      tenant_display_name={has_second_tenant && tenant_display_name}
      tenant_index={has_second_tenant ? 0 : undefined}
    />
  );
}

async function proceed({token, fields, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.current_address,
      token,
      tenant_index: 0,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
