import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import classNames from 'classnames/bind';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {_} from 'shared/l10n.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';

import {alert} from '../../../effects.js';
import BottomNavigation from '../../../components/BottomNavigation.js';
import FormWrapper from '../../../components/FormWrapper.js';
import Input from '../../../components/Input.js';
import RadioGroup from '../../../components/RadioGroup.js';
import FormHeader from '../../../components/FormHeader.js';
import {isDevFormEnabled} from '../../../lib/utils.js';
import SecurityBanner from '../../../components/security_banner/SecurityBanner.js';

import styles from './PropertyInfoScreen.module.scss';

export default function PropertyInfoScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => {
    proceed({token, fields, history, setError});
  });

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.PropertyInfoScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  const property_type_selected = watch('property_type');

  return (
    <FormWrapper>
      <FormHeader
        title={_('Adresse Kautionsbürgschaft')}
        subtitle={_(
          'Wie lautet die Adresse der Mieteinheit, für die der Schutz ausgestellt werden soll?',
        )}
      />

      <div className={styles.row}>
        <Controller
          control={control}
          name="property_type"
          render={({field: {onChange, value, name}}) => (
            <RadioGroup
              label={_('Um welche Art Mieteinheit handelt es sich?')}
              name={name}
              onChange={onChange}
              value={value}
              error={errors[name]?.message}
              option_1="apartment"
              option_1_label={_('Wohnung')}
              option_2="house"
              option_2_label={_('Haus')}
            />
          )}
        />
      </div>

      {property_type_selected && (
        <>
          <div className={styles.row}>
            <Input
              defaultValue={contract_details?.street_name || ''}
              label={_('Straße')}
              error={errors.street_name?.message}
              className={styles.street_name}
              {...register('street_name')}
            />
            <Input
              defaultValue={contract_details?.street_number || ''}
              label={_('Hausnummer')}
              error={errors.street_number?.message}
              {...register('street_number')}
            />
          </div>

          {property_type_selected === 'apartment' && (
            <Input
              defaultValue={contract_details?.storey || ''}
              label={_('Etage')}
              error={errors.storey?.message}
              {...register('storey')}
            />
          )}

          <div className={styles.row}>
            <Input
              defaultValue={contract_details?.postal_code || ''}
              label={_('PLZ')}
              error={errors.postal_code?.message}
              className={styles.postal_code}
              {...register('postal_code')}
            />
            <Input
              defaultValue={contract_details?.region || ''}
              label={_('Ort')}
              error={errors.region?.message}
              className={styles.region}
              {...register('region')}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.country_container}>
              <div
                className={classNames(text_styles.caption_left, styles.label)}>
                {_('Land')}
              </div>
              <div
                className={styles.country_select}
                onClick={() => {
                  alert({
                    title: _(
                      'Wir bauen unseren Service aus, aktuell gilt dieser jedoch nur in Deutschland.',
                    ),

                    text: _(
                      'Bitte senden Sie eine E-Mail an kundenservice@getmomo.de, falls Sie unseren Service in einem anderen Land nutzen möchten.',
                    ),
                  });
                }}>
                {_('Deutschland')}
              </div>
            </div>
          </div>
        </>
      )}

      <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />

      <SecurityBanner />
    </FormWrapper>
  );
}

async function proceed({fields, token, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.property_info,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
