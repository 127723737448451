import React from 'react';
import classNames from 'classnames/bind';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';

import styles from './RadioGroup.module.scss';

export default function RadioGroup({
  label,
  className,
  name,
  error = '',
  onChange,
  value,
  option_1,
  option_1_label = _('Ja'),
  option_2,
  option_2_label = _('Nein'),
}) {
  const button_yes_id = `${name}_yes`;
  const button_no_id = `${name}_no`;

  return (
    <div className={classNames(styles.radio_group_container, className)}>
      {label && (
        <div className={classNames(text_styles.caption_left, styles.label)}>
          {label}
        </div>
      )}
      <div className={styles.container}>
        <input
          type="radio"
          className={styles.radio_button}
          name={name}
          id={button_yes_id}
          value={option_1 || 'true'}
          onChange={() => onChange(option_1 || true)}
          checked={value === (option_1 || true)}
        />
        <label
          htmlFor={button_yes_id}
          className={text_styles.button_bold_bright}>
          {option_1_label}
        </label>
        <input
          type="radio"
          className={styles.radio_button}
          name={name}
          id={button_no_id}
          value={option_2 || 'false'}
          checked={value === (option_2 || false)}
          onChange={() => onChange(option_2 || false)}
        />
        <label
          htmlFor={button_no_id}
          className={text_styles.button_bold_bright}>
          {option_2_label}
        </label>
      </div>
      <p className={classNames(text_styles.caption_left, styles.error)}>
        {error}
      </p>
    </div>
  );
}
