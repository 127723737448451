import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {_} from 'shared/l10n.js';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';
import Select from 'shared-web/components/Select.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';

import BottomNavigation from '../../../components/BottomNavigation.js';
import FormWrapper from '../../../components/FormWrapper.js';
import Input from '../../../components/Input.js';
import RadioGroup from '../../../components/RadioGroup.js';
import {getNationalityOptions, isDevFormEnabled} from '../../../lib/utils.js';
import FormHeader from '../../../components/FormHeader.js';

import styles from './ManagerInfoScreen.module.scss';

export default function ManagerInfoScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => {
    proceed({token, fields, history, setError});
  });

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.ManagerInfoScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  useEffect(() => {
    reset(contract_details);
  }, [contract_details, reset]);

  const has_manager = watch('has_manager');

  return (
    <FormWrapper>
      <FormHeader
        title={_('Hausverwaltung')}
        subtitle={_(
          'Gibt es eine Hausverwaltung, die Ihre Mieteinheit für den Eigentümer verwaltet? *',
        )}
      />

      <div className={styles.row}>
        <Controller
          control={control}
          name="has_manager"
          defaultValue={contract_details?.has_manager}
          render={({field: {onChange, value, name}}) => (
            <RadioGroup
              name={name}
              onChange={onChange}
              value={value}
              error={errors[name]?.message}
              option_1={true}
              option_1_label={_('Ja')}
              option_2={false}
              option_2_label={_('Nein')}
            />
          )}
        />
      </div>

      {has_manager && (
        <>
          <Input
            defaultValue={contract_details?.manager_company_name || ''}
            placeholder={_('z. B. Schmitt Immobilien GmbH')}
            label={_('Name und Rechtsform der Hausverwaltung')}
            error={errors.manager_company_name?.message}
            {...register('manager_company_name')}
          />

          <div className={styles.row}>
            <Input
              defaultValue={contract_details?.manager_street_name || ''}
              label={_('Straße')}
              error={errors.manager_street_name?.message}
              className={styles.manager_street_name}
              {...register('manager_street_name')}
            />
            <Input
              defaultValue={contract_details?.manager_street_number || ''}
              label={_('Hausnummer')}
              error={errors.manager_street_number?.message}
              {...register('manager_street_number')}
            />
          </div>

          <div className={styles.row}>
            <Input
              defaultValue={contract_details?.manager_postal_code || ''}
              label={_('PLZ')}
              error={errors.manager_postal_code?.message}
              className={styles.manager_postal_code}
              {...register('manager_postal_code')}
            />
            <Input
              defaultValue={contract_details?.manager_region || ''}
              label={_('Ort')}
              error={errors.manager_region?.message}
              className={styles.manager_region}
              {...register('manager_region')}
            />
          </div>

          <Select
            label={_('Land')}
            options={getNationalityOptions()}
            defaultValue={contract_details?.manager_country || 'DE'}
            {...register('manager_country')}
            className={styles.manager_country}
          />
        </>
      )}

      <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />

      <p className={classNames(text_styles.caption_left, styles.helper_text)}>
        {_('* Diese Angabe finden Sie auf der Vorderseite des Mietvertrages')}
      </p>
    </FormWrapper>
  );
}

async function proceed({fields, token, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.manager_info,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
