import classNames from 'classnames';
import React from 'react';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';

import styles from './Footer.module.scss';

export default function Footer({
  display_customer_service_email,
  display_momo_website_link,
}) {
  return (
    <footer className={styles.container}>
      {display_customer_service_email && <DisplayCustomerServiceEmail />}
      {!display_customer_service_email && display_momo_website_link && (
        <DisplayMomoWebsiteLink />
      )}

      <ul className={styles.legal_container}>
        <LegalLink
          href={_('https://www.getmomo.de/impressum')}
          title={_('Impressum')}
        />
        {'|'}
        <LegalLink
          href={_('https://www.getmomo.de/datenschutz')}
          title={_('Datenschutz')}
        />
        {'|'}
        <LegalLink href={_('https://www.getmomo.de/agb')} title={_('AGB')} />
      </ul>
    </footer>
  );
}

function DisplayCustomerServiceEmail() {
  return (
    <div
      className={classNames(
        styles.customer_service_block_container,
        text_styles.body1_bright_centered,
      )}>
      {_(
        'Bei Fragen können Sie jederzeit den Momo Support kontaktieren. Sie erreichen uns über folgende E-Mail Adresse:',
      )}
      <br />
      <div className={styles.customer_service_email_container}>
        <a
          className={classNames(
            text_styles.body1_bright_bold_centered,
            styles.momo_link,
          )}
          href="mailto:kundenservice@getmomo.de">
          {_('kundenservice@getmomo.de')}
        </a>
      </div>
    </div>
  );
}

function DisplayMomoWebsiteLink() {
  return (
    <a
      className={classNames(
        text_styles.body1_bright_bold_centered,
        styles.momo_link,
      )}
      href={_('https://www.getmomo.de/mieter/')}
      rel="noreferrer"
      target="_blank">
      {_('Klicken Sie hier, um mehr über Momo zu erfahren')}
    </a>
  );
}

function LegalLink({href, title}) {
  return (
    <li>
      <a
        className={classNames(
          text_styles.body1_bright_bold_centered,
          styles.legal_link,
        )}
        href={href}
        rel="noreferrer"
        target="_blank">
        {title}
      </a>
    </li>
  );
}
