import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {isRentDepositTransfer} from 'shared/utils.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';

import PreviousAddressStep from '../../../components/steps/PreviousAddressStep/PreviousAddressStep.js';
import {
  getSecondTenantDisplayName,
  isDevFormEnabled,
} from '../../../lib/utils.js';

export default function PreviousAddressScreen2() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  const goToNextStep = useCallback(
    ({fields, setError}) => {
      proceed({token, fields, history, setError});
    },
    [history, token],
  );

  const saveAndGoBack = useCallback(
    async ({fields}) => {
      contract_details.tenants[1] = {...contract_details.tenants[1], ...fields};

      const {previous_step} = await RPC('getNavigationState', {
        path: TENANT_PATHS.PreviousAddressScreen2,
        contract_details,
      });
      history.push(previous_step, {contract_details, token});
    },
    [contract_details, history, token],
  );

  return (
    <PreviousAddressStep
      contract_details={contract_details?.tenants[1]}
      goToNextStep={goToNextStep}
      saveAndGoBack={saveAndGoBack}
      tenant_display_name={getSecondTenantDisplayName(contract_details)}
      tenant_index={1}
      current_address={getCurrentAddress(contract_details)}
    />
  );
}

async function proceed({token, fields, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.previous_address,
      tenant_index: 1,
      token,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}

export function getCurrentAddress(contract_details) {
  if (isRentDepositTransfer(contract_details.rental_contract_start_date)) {
    return {
      current_street_name: contract_details.street_name,
      current_street_number: contract_details.street_number,
      current_region: contract_details.region,
    };
  }

  if (contract_details?.tenants[1]?.use_first_tenant_address) {
    return {
      current_street_name: contract_details.tenants[0].current_street_name,
      current_street_number: contract_details.tenants[0].current_street_number,
      current_region: contract_details.tenants[0].current_region,
    };
  }

  return {
    current_street_name: contract_details.tenants[1].current_street_name,
    current_street_number: contract_details.tenants[1].current_street_number,
    current_region: contract_details.tenants[1].current_region,
  };
}
