import classNames from 'classnames/bind.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import {ReactComponent as DiscountSvg} from './icon_discount.svg';
import styles from './PromotionCard.module.scss';

export default function PromotionCard({header, body}) {
  if (!header && !body) return null;

  return (
    <div className={styles.promotion_container}>
      <div className={styles.content}>
        <div>
          <p
            className={classNames(
              text_styles.typeface_bright_bold,
              styles.title,
            )}>
            {header}
          </p>
          <p className={text_styles.caption_bright_centered}>{body}</p>
        </div>
        <DiscountSvg className={styles.icon} />
      </div>
    </div>
  );
}
