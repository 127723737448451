import {Provider} from 'react-redux';
import ModalConnector from 'shared-web/components/modal/ModalConnector.js';
import {NotifyProvider} from 'shared-web/NotifyProvider.js';

import Router from './Router.js';
import store from './store/store.js';

export default function App() {
  return (
    <Provider store={store}>
      <NotifyProvider>
        <ModalConnector />
        <Router />
      </NotifyProvider>
    </Provider>
  );
}
