import classNames from 'classnames';
import React from 'react';
import {_} from 'shared/l10n.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import image from './contract-success.png';
import styles from './SuccessScreen.module.scss';

export default function SuccessScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.image}>
          <img
            src={image}
            alt="Mietkautionsbürgschaft"
            height="265px"
            width="366px"
          />
        </div>

        <div className={styles.thanks_container}>
          <p
            className={classNames(
              text_styles.huge_headline_left,
              styles.headline,
            )}>
            {_('Vielen Dank')}
          </p>
        </div>
        <p
          className={classNames(
            text_styles.body1_centered,
            styles.data_received_text,
          )}>
          {_(
            'Wir haben Ihre Daten erhalten. Im Normalfall dauert die Überprüfung nur wenige Minuten, sie kann jedoch bis zu 2 Tage in Anspruch nehmen.',
          )}
        </p>
        <p
          className={classNames(
            text_styles.body1_bold_centered,
            styles.sms_text,
          )}>
          {_(
            'Wir senden Ihnen eine SMS, sobald das Angebot in Ihrem Postfach eingegangen ist.',
          )}
        </p>
        <p
          className={classNames(
            text_styles.body1_centered,
            styles.reach_us_text,
          )}>
          {_('Bei Fragen erreichen Sie uns hier:')}
        </p>
        <p className={text_styles.body1_bold_centered}>
          <a
            href={`mailto:${_('kundenservice@getmomo.de')}`}
            className={classNames(
              text_styles.body1_bold_centered,
              styles.email,
            )}>
            {_('kundenservice@getmomo.de')}
          </a>
        </p>
      </div>
    </div>
  );
}
