import React from 'react';
import {useHistory} from 'react-router-dom';
import {_} from 'shared/l10n.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import Button from 'shared-web/components/Button.js';
import classNames from 'classnames';
import {RPC} from 'shared/api.js';
import {TENANT_PATHS} from 'shared/constants.js';

import BackButton from '../../../components/BackButton.js';

import {ReactComponent as OneSvg} from './circle_1.svg';
import {ReactComponent as TwoSvg} from './circle_2.svg';
import {ReactComponent as ThreeSvg} from './circle_3.svg';
import styles from './SecondTenantInfoScreen.module.scss';
import hand from './gfx_victory.png';

export default function SecondTenantInfoScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {tenant_display_name} = contract_details;

  async function onReturn() {
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.SecondTenantInfoScreen,
      contract_details,
    });

    history.push(previous_step, {
      contract_details,
      token,
    });
  }

  async function onContinue() {
    const {next_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.SecondTenantInfoScreen,
      contract_details,
    });

    history.push(next_step, {
      contract_details,
      token,
    });
  }

  return (
    <div className={styles.background}>
      <div className={styles.header}>
        <BackButton onReturn={onReturn} />
      </div>
      <div className={styles.container}>
        <section className={styles.section}>
          <img
            src={hand}
            alt="momo hand"
            className={styles.hand}
            height="254px"
            width="327px"
          />
          <section className={styles.text_wrapper}>
            <span className={styles.text_background}>
              <p className={text_styles.h1_bold_centered}>
                {_('Wir benötigen')}
              </p>
            </span>
            <span className={styles.text_background}>
              <p className={text_styles.h1_bold_centered}>
                {_('folgende Angaben:')}
              </p>
            </span>
          </section>
          <OneSvg className={styles.circle} />
          <p
            className={classNames(
              text_styles.body1_bold_centered,
              styles.title,
            )}>
            {_('Mieter 1 (%1)', tenant_display_name)}
          </p>

          <p className={text_styles.body1_centered}>
            {_('Adresse, Einkommensnachweis, Persönliche Daten.')}
          </p>
        </section>
        <section className={styles.section}>
          <TwoSvg className={styles.circle} />
          <p
            className={classNames(
              text_styles.body1_bold_centered,
              styles.title,
            )}>
            {_('Mieter 2')}
          </p>
          <p className={text_styles.body1_centered}>
            {_(
              'Nach den Fragen für Mieter 1, zeigen wir die gleichen Fragen für Mieter 2 an.',
            )}
          </p>
        </section>
        <section className={styles.section}>
          <ThreeSvg className={styles.circle} />
          <p
            className={classNames(
              text_styles.body1_bold_centered,
              styles.title,
            )}>
            {_('Zusammenfassung & Angebot')}
          </p>
          <p className={text_styles.body1_centered}>
            {_(
              'Sie bekommen eine Zusammenfassung angezeigt und können Ihr Angebot anfordern.',
            )}
          </p>
          <Button
            onClick={onContinue}
            className={styles.continue_button}
            title={_('Weiter')}
          />
        </section>
      </div>
    </div>
  );
}
