import React, {useEffect} from 'react';
import {_} from 'shared/l10n.js';
import CircleSpinner from 'shared-web/components/CircleSpinner.js';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './TenantLedOnboardingScreen.module.scss';

export default function TenantLedOnboardingScreen() {
  useEffect(() => {
    const redirectUrl = new URL(
      '/tenant-led-onboarding',
      process.env.REACT_APP_API_URL,
    );
    window.location.replace(redirectUrl.toString());
  });

  return (
    <Layout>
      <div className={styles.container}>
        <Title label={_('Sie werden weiter geleitet.')} />
        <CircleSpinner />
      </div>
    </Layout>
  );
}
