import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Select from 'shared-web/components/Select';
import {_} from 'shared/l10n.js';

import FormWrapper from '../../FormWrapper.js';
import BottomNavigation from '../../BottomNavigation.js';
import Input from '../../Input.js';
import {getNationalityOptions} from '../../../lib/utils.js';
import InvitationStepper from '../../InvitationStepper.js';
import FormHeader from '../../FormHeader.js';

import styles from './PreviousAddressStep.module.scss';

export default function PreviousAddressStep({
  contract_details,
  goToNextStep,
  saveAndGoBack,
  bottom_navigation_label,
  tenant_display_name,
  tenant_index = null,
  current_address,
}) {
  const {
    handleSubmit,
    formState: {errors},
    reset,
    register,
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => goToNextStep({fields, setError}));
  const onBack = useCallback(
    () => saveAndGoBack({fields: getValues()}),
    [saveAndGoBack, getValues],
  );

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  const {current_street_name, current_street_number, current_region} =
    current_address;

  return (
    <>
      <FormWrapper tenant_display_name={tenant_display_name}>
        <FormHeader
          title={_('Vorherige Adresse')}
          subtitle={_(
            'Wie lautete Ihre Adresse, bevor Sie in der %1 %2 in %3 wohnten?',
            current_street_name,
            current_street_number,
            current_region,
          )}
        />

        <div className={styles.row}>
          <Input
            label={_('Straße')}
            error={errors.previous_street_name?.message}
            className={styles.grow_3}
            {...register('previous_street_name')}
          />
          <Input
            label={_('Hausnummer')}
            error={errors.previous_street_number?.message}
            className={styles.grow_1}
            {...register('previous_street_number')}
          />
        </div>
        <div className={styles.row}>
          <Input
            label={_('PLZ')}
            error={errors.previous_postal_code?.message}
            className={styles.grow_1}
            {...register('previous_postal_code')}
          />
          <Input
            label={_('Ort')}
            error={errors.previous_region?.message}
            className={styles.grow_3}
            {...register('previous_region')}
          />
        </div>
        <Select
          label={_('Land')}
          defaultValue="DE"
          options={getNationalityOptions()}
          className={styles.margin_bottom}
          error={errors.previous_country?.message}
          {...register('previous_country')}
        />

        <BottomNavigation
          onSubmit={onSubmit}
          onBack={onBack}
          label={bottom_navigation_label}
        />

        {tenant_index !== null && <InvitationStepper step={tenant_index + 1} />}
      </FormWrapper>
    </>
  );
}
